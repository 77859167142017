<template>
  <Layout>
    <div class="section-content max-w-4xl mx-auto">
      <div class="form">
        <div class="form-heading text-center mb-6" v-html="confirmationHtml">

        </div>
        <div
          class="flex flex-col sm:flex-row justify-center items-center"
          v-if="!candidate.situacao"
        >
          <Button
            color="white"
            background="lime-600"
            background-hover="lime-700"
            size="large"
            :rounded="true"
            :group="true"
            @action="confirm"
          >
            {{
              associate.ocupa_cargo
                ? "Desistir do cargo atual e confirmar participação"
                : "Confirmar participação"
            }}
          </Button>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import Layout from "@/components/layout/subscription/Layout.vue";
import Button from "@/components/elements/Button.vue";
import services from "@/services";
import Handlebars from 'handlebars'

export default Vue.extend({
  name: "SubscriptionConfirmation",
  data() {
    return {
      confirmationHtml: "",
      token: "",
      candidate: {},
      associate: {},
      responsavel: {}
    };
  },
  components: {
    Layout,
    Button,
  },
  computed: {
    ...mapGetters("eleicaonet", ["getParamHotsite"]),

  },
  methods: {
    ...mapActions(["changeLoading", "sendNotification"]),
    async confirm() {
      this.changeLoading(true);

      await services.Subscription.updateConfirmCandidate(
        this.token,
        this.associate.ocupa_cargo
      )
        .then(() => {
          this.changeLoading(false);

          this.sendNotification({
            type: "success",
            message: "Participação confirmada com sucesso!",
          });

          this.$router.push({ name: "SubscriptionSuccessful" });
        })
        .catch((reason) => {
          this.changeLoading(false);

          const error = reason.response.data.error;
          this.sendNotification({
            type: "error",
            message: error.message,
          });

          this.$router.push({ name: "SubscriptionLogin" });
        });
    },
  },
  async mounted() {
    this.changeLoading(true);
    this.token = this.$route.query.token;
    try {
      let response = await services.Subscription.getCandidate(this.token);
      this.changeLoading(false);

      this.candidate = response.data.inscrito;
      this.associate = response.data.associado;
      this.responsavel = response.data.responsavel

      let  page, templateSource, template
      try {
        console.log(JSON.stringify(this.getParamHotsite))
        page = this.getParamHotsite.pages.subscription
        if (page && page.confirmation) {
          templateSource = page.confirmation.template
        } else {
          templateSource = `
       <h5 class="mb-4">Olá {{ inscrito.nome_completo }}</h5>
          <p>
            Para confirmar sua participação na inscrição, clique no botão
            abaixo:
          </p>
          {{#if associado.ocupa_cargo}}
          <p v-if="" class="mt-5 text-red-600 font-medium">
            ATENÇÃO<br />Verificamos que você já possui um cargo, caso
            confirme sua participação,<br />você estará desistindo do cargo
            atual.
          </p>
          {{/if}}
      `
        }
        template  = Handlebars.compile(templateSource)
        this.confirmationHtml = template(response.data)
      } catch(reason) {
        console.error(reason)
      }

      if (this.candidate.situacao) {
        this.sendNotification({
          type: "success",
          message: "Participação já foi confirmada.",
        });

        this.$router.push({ name: "SubscriptionLogin" });
      }
    } catch (reason) {
      this.changeLoading(false);

      const error = reason.response.data.error;
      this.sendNotification({
        type: "error",
        message: error.message,
      });
      this.$router.push({ name: "SubscriptionLogin" });
    }
  }
});
</script>
